@import '../../../../style';

.adyen-checkout__fieldset {
    display: block;
    padding-bottom: 8px;
    width: 100%;
}

.adyen-checkout__fieldset:last-of-type {
    padding-bottom: 0;
}

.adyen-checkout__fieldset + .adyen-checkout__fieldset {
    margin-top: 16px;
}

.adyen-checkout__fieldset__title {
    color: $color-gray-darker;
    display: block;
    font-size: $font-size-xxsmall;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
    padding: 0 0 12px;
    text-transform: uppercase;
}

.adyen-checkout__fieldset__fields,
.adyen-checkout__field-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.adyen-checkout__field-group:last-of-type .adyen-checkout__field {
    margin-bottom: 0;
}

.adyen-checkout__fieldset--readonly .adyen-checkout__fieldset__fields {
    color: $color-black;
    font-size: $font-size-small;
    line-height: 19px;
    margin: 0;
}
