@import '../../../style';

.adyen-checkout__button-group {
    background: transparent;
    display: flex;
    justify-content: space-between;

    .adyen-checkout__button {
        background: transparent;
        border: 0;
        box-shadow: inset 0 0 0 1px #99a3ad;
        color: $color-black;
        font-size: $font-size-small;
        font-weight: normal;
        line-height: 40px;
        margin-right: 8px;
        height: 40px;
        padding: 0;
        text-align: center;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            background: transparent;
            box-shadow: inset 0 0 0 2px #99a3ad;
        }

        &:active {
            background: $color-gray-lighter;
            box-shadow: inset 0 0 0 2px #99a3ad;
        }

        &--disabled {
            &,
            &:hover {
                cursor: not-allowed;
                opacity: 0.4;
                user-select: none;
            }
        }

        &--selected {
            &,
            &:hover,
            &:active,
            &:active:hover {
                background: $color-blue-lighter;
                box-shadow: inset 0 0 0 2px $color-blue;
                color: $color-blue;
                font-weight: 500;
                height: 40px;
                transition: none;
            }
        }

        .adyen-checkout__button-group__input {
            opacity: 0;
            pointer-events: none;
            position: absolute;
        }
    }
}
