@import '../../style';

.adyen-checkout__adyen-giving {
    .adyen-checkout__status__icon {
        display: block;
        margin: $spacing-xxxlarge auto $spacing-xlarge;
    }

    .adyen-checkout__status__text {
        color: $color-black;
        margin-bottom: $spacing-xxxlarge;
        text-align: center;
    }
}

.adyen-checkout__campaign {
    border-radius: $border-radius-medium;
    background: $color-black;
    height: 227px;
    overflow: hidden;
    position: relative;
}

.adyen-checkout__campaign-link:hover .adyen-checkout__campaign-description {
    text-decoration: underline;
}

.adyen-checkout__campaign-container {
    height: 100%;
}

.adyen-checkout__campaign-logo {
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-radius: $border-radius-small;
    display: block;
    height: 48px;
    margin-bottom: $spacing-medium;
    overflow: hidden;
    width: 48px;
}

.adyen-checkout__campaign-background-image {
    background-color: $color-black;
    background-position: center;
    background-size: cover;
    height: 100%;
}

.adyen-checkout__campaign-link {
    .adyen-checkout__campaign-background-image {
        &:before {
            background: inherit;
            content: '';
            height: 100%;
            position: absolute;
            transition: transform 0.6s ease-out;
            width: 100%;
        }

        &:hover:before {
            transform: scale(1.1);
        }
    }

    .adyen-checkout__campaign-content {
        pointer-events: none;
    }
}

.adyen-checkout__campaign-content {
    bottom: 0;
    padding: $spacing-medium;
    position: absolute;
    z-index: 2;
}

.adyen-checkout__campaign-title,
.adyen-checkout__campaign-description {
    color: $color-white;
    font-weight: normal;
    margin: 0;
}

.adyen-checkout__campaign-title {
    font-size: $font-size-medium;
    margin-bottom: $spacing-small;
}

.adyen-checkout__campaign-description {
    font-size: $font-size-small;
    line-height: 19px;
}

.adyen-checkout__adyen-giving-actions {
    margin-top: $spacing-medium;
}

.adyen-checkout__button {
    &.adyen-checkout__button--donate {
        margin: $spacing-medium auto $spacing-small;
    }

    &.adyen-checkout__button--decline {
        display: block;
        margin: auto;
        width: initial;
    }
}
