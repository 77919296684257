@import '../../style';

.adyen-checkout__amazonpay__button {
    margin: auto;
}

.adyen-checkout__amazonpay .adyen-checkout__button--ghost {
    display: block;
    margin: $spacing-small auto 0;
    width: auto;
}
