@import '../../../../style';

.card-input {
    &__wrapper {
        position: relative;
    }

    &__wrapper *,
    &__wrapper *::before,
    &__wrapper *::after {
        box-sizing: border-box;
    }

    &__icon {
        border-radius: $border-radius-small;
        position: absolute;
        right: 10px;
        margin-left: 7px;
        transform: translateY(-50%);
        top: 50%;
        height: 18px;
        width: 27px;
    }

    &__form {
        opacity: 1;
    }

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: none;
    }

    &__spinner--active {
        display: block;
    }

    &__form--loading {
        opacity: 0;
    }
}

.adyen-checkout__input {
    display: block;
    max-height: 100px;
}

.adyen-checkout__card__cvc__input--hidden {
    display: none;
}

.revolving-plan-installments{
    &__disabled{
        pointer-events: none;
        opacity: 0.4;
    }
}