@import '../../../../style';

.adyen-checkout__dropdown {
    max-width: 100%;
    width: 100%;
    font-size: $font-size-medium;
}

.adyen-checkout__dropdown__button {
    padding: 7px 24px 7px 12px;
    border: 1px solid $color-gray-dark;
    background: $color-white;
    color: $color-black;
    text-decoration: none;
    border-radius: $border-radius-medium;
    outline: 0;
    width: 100%;
    font-size: $font-size-medium;
    height: 40px;
    line-height: 20px;
    user-select: none;
    transition: border 0.2s ease-out, box-shadow 0.2s ease-out;
}

.adyen-checkout__dropdown__button:hover {
    border-color: #99a3ad;
}

.adyen-checkout__dropdown__button__icon {
    margin-right: 8px;
    margin-left: auto;
    max-width: 40px;
    height: 26px;
    border-radius: 3px;
}

.adyen-checkout__dropdown__button--active,
.adyen-checkout__dropdown__button--active:hover,
.adyen-checkout__dropdown__button:active,
.adyen-checkout__dropdown__button:focus {
    border-color: $color-primary;
    box-shadow: 0 0 0 2px $color-blue-light;
}

.adyen-checkout__dropdown__button--readonly {
    &,
    &--active,
    &:hover,
    &:focus {
        background: $color-gray-light;
        border-color: transparent;
        color: $color-black;
        cursor: not-allowed;
    }
}

.adyen-checkout__dropdown__button--readonly:after {
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='7' viewBox='0 0 8 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.19471 6.5646C3.59429 7.09797 4.39396 7.0986 4.79439 6.56587L7.78716 2.58424C8.28257 1.92514 7.81232 0.983398 6.98779 0.983398L1.01209 0.983398C0.188292 0.983398 -0.282154 1.92367 0.211778 2.58298L3.19471 6.5646Z' fill='%23B9C4C9'/%3E%3C/svg%3E%0A");
}

.adyen-checkout__dropdown__button--invalid {
    border-color: $color-alert;
}

.adyen-checkout__dropdown__button__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adyen-checkout__dropdown__list {
    z-index: 2;
    border-radius: $border-radius-medium;
    max-height: 375px;
    box-shadow: 0 2px 7px rgba(0, 15, 45, 0.3);
}

.adyen-checkout__dropdown__list.adyen-checkout__dropdown__list--active {
    margin-top: 2px;
}

.adyen-checkout__dropdown__element {
    padding: 8px;
    line-height: 20px;
    border: 1px solid transparent;
    word-break: break-word;
    hyphens: auto;
    cursor: pointer;
    font-size: $font-size-small;
    outline: 0;
    user-select: none;
    transition: background 0.2s ease-out, border-color 0.2s ease-out;
}

.adyen-checkout__dropdown__element:last-child {
    border-bottom: 0;
}

.adyen-checkout__dropdown__element:hover,
.adyen-checkout__dropdown__element:focus,
.adyen-checkout__dropdown__element:active {
    background: rgba(230, 233, 235, 0.6);
}

.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active {
    background: rgba(0, 102, 255, 0.1);
}

.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:hover,
.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:focus,
.adyen-checkout__dropdown__element.adyen-checkout__dropdown__element--active:active {
    background: rgba(0, 102, 255, 0.15);
}

.adyen-checkout__dropdown__element__icon {
    border-radius: $border-radius-small;
    margin-right: 8px;
    max-width: 40px;
    max-height: 26px;
}

.adyen-checkout__dropdown__element__flag {
    margin-left: 8px;
    margin-right: 10px;
    max-width: 27px;
    max-height: 18px;
}

.adyen-checkout__dropdown + .adyen-checkout-input__inline-validation {
    right: 32px;
}
