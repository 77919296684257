.adyen-checkout__card__dual-branding__buttons{
  display: flex;
  opacity: 0.25;
  pointer-events: none;

  &--active{
    opacity: 1;
    pointer-events: auto;
  }

  .adyen-checkout__card__cardNumber__brandIcon{
    cursor: pointer;
    opacity: 1;

    &:nth-child(1){
      right: 40px;
    }

    &--not-selected{
      opacity: 0.25;
    }
  }
}