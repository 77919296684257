@import '../../../style';

.loading-input {
    &__form {
        opacity: 1;
    }

    &__form--loading {
        opacity: 0;
    }

    &__spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: none;
    }

    &__spinner--active {
        display: block;
    }
}
