.adyen-checkout__payment-methods-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.adyen-checkout__payment-method {
    display: block;
    max-height: 60px;

    &__details {
        display: none;
    }

    &__image {
        width: 40px;
        height: 26px;

        &__wrapper {
            margin-right: 8px;
        }
    }

    &--selected {
        max-height: 100%;

        .adyen-checkout__payment-method__details {
            display: block;
        }
    }
}
